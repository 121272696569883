@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  .custom-whatsapp-link {
    color: #0255C3;
    text-decoration: none;
    font-weight: bold;
  }
  
  .custom-whatsapp-link:hover {
    color: #033E75;
  }